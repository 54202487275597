import {
	ref,
	computed,
} from 'vue';
import { useStore } from 'vuex';

// Use one instance for all buttons
const stripeInstance = ref(null);

// One time payment with checkout docs:
// https://stripe.com/docs/payments/checkout/client

export const useStripeGridButton = (props) => {
	const priceId = computed(() => props.data.settings?.priceId || '');
	const paymentType = computed(() => props.data.settings?.paymentType || 'payment');
	const { getters } = useStore();

	const successPageSlug = computed(() => (
		getters.currentLanguageData.pages[props.data.settings?.successPageId] || Object.values(getters.currentLanguageData.pages)[0]).slug);
	const cancellationPageSlug = computed(() => (
		getters.currentLanguageData.pages[props.data.settings?.cancellationPageId]
		|| Object.values(getters.currentLanguageData.pages)[0]).slug);

	return {
		stripeInstance,
		priceId,
		paymentType,
		successPageSlug,
		cancellationPageSlug,
	};
};
