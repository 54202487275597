<template>
	<div
		class="product-carousel"
		:style="carouselStyle"
	>
		<div
			ref="carouselRef"
			class="product-carousel__image-wrapper"
			:class="{ 'product-carousel__image-wrapper--contain' : imageRatio === 'contain' }"
		>
			<template v-if="isMoreThanOneImage && !isQuickPreview">
				<button
					data-qa="product-carousel-button-prev"
					class="product-carousel__arrow product-carousel__arrow--left"
					@click="goToPreviousSlide"
				/>
				<button
					data-qa="product-carousel-button-next"
					class="product-carousel__arrow product-carousel__arrow--right"
					@click="goToNextSlide"
				/>
			</template>
			<template v-if="images.length">
				<TransitionGroup
					name="fade"
					mode="out-in"
					class="product-carousel__image-content"
					tag="div"
				>
					<ProductImage
						v-for="(image, index) in images"
						v-show="currentIndex === index"
						:key="`${image.url}${index}`"
						:src="image.url"
						:alt="productTitle"
						class="product-carousel__image product-carousel__main-image"
						:is-eager="isEager"
						:object-fit="imageRatio"
						:width="imageMaxWidth"
						:height="imageMaxWidth"
						enable-srcset
					/>
				</TransitionGroup>
			</template>
			<ProductImagePlaceholder
				v-else
				class="product-carousel__image product-carousel__main-image"
			/>
		</div>
		<div
			v-if="isMoreThanOneImage && !isQuickPreview"
			class="product-carousel__image-list"
		>
			<button
				v-for="(image, index) in images"
				:key="`image-${index}`"
				class="product-carousel__image-list-element"
				:class="{ 'product-carousel__image-list-element--active': index === currentIndex }"
				@click="currentIndex = index"
			>
				<ProductImage
					:src="image.url"
					:alt="productTitle"
					class="product-carousel__image"
					:is-eager="isEager"
					:object-fit="imageRatio"
					:width="IMAGE_MAX_WIDTH_SMALL_PX"
					:height="IMAGE_MAX_WIDTH_SMALL_PX"
				/>
			</button>
		</div>
		<div
			v-if="isMoreThanOneImage"
			class="product-carousel__dots-wrapper"
		>
			<button
				v-for="(_, index) in images"
				:key="`image-dot-${index}`"
				class="product-carousel__dot-button"
				@click="currentIndex = index"
			>
				<div
					class="product-carousel__dot"
					:class="{ 'product-carousel__dot--active': index === currentIndex }"
				/>
			</button>
		</div>
	</div>
</template>

<script>
import { moveDirection } from '@zyro-inc/site-modules/utils/moveDirection';
import ProductImage from '@zyro-inc/site-modules/components/ecommerce/ProductImage.vue';
import ProductImagePlaceholder from '@zyro-inc/site-modules/components/ecommerce/-partials/ProductImagePlaceholder.vue';

import { defineComponent } from 'vue';

const IMAGE_MAX_WIDTH_PX_DEFAULT = 600;
const IMAGE_MAX_WIDTH_PX_PREVIEW = 400;
const IMAGE_MAX_WIDTH_SMALL_PX = 64;

export default defineComponent({
	components: {
		ProductImage,
		ProductImagePlaceholder,
	},

	props: {
		images: {
			type: Array,
			required: true,
		},
		productTitle: {
			type: String,
			required: true,
		},
		arrowsColor: {
			type: String,
			default: 'unset',
		},
		imageRatio: {
			type: String,
			default: 'unset',
		},
		imageBorderRadius: {
			type: String,
			default: 'unset',
		},
		isEager: {
			type: Boolean,
			default: false,
		},
		variantImage: {
			type: String,
			default: null,
		},
		isQuickPreview: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			IMAGE_MAX_WIDTH_SMALL_PX,
			currentIndex: 0,
		};
	},

	computed: {
		isMoreThanOneImage() {
			return this.images.length > 1;
		},
		imageMaxWidth() {
			return this.isQuickPreview ? IMAGE_MAX_WIDTH_PX_PREVIEW : IMAGE_MAX_WIDTH_PX_DEFAULT;
		},
		carouselStyle() {
			return {
				'--image-max-width': `${this.imageMaxWidth}px`,
				'--image-max-width-small': `${IMAGE_MAX_WIDTH_SMALL_PX}px`,
				'--image-object-fit': this.imageRatio,
				'--image-border-radius': this.imageRatio === 'cover' ? this.imageBorderRadius : 0,
				// position set to absolute to make image a responsive square on mobile
				'--image-position': this.imageRatio === 'cover' ? 'absolute' : 'relative',
				'--arrow-color': this.arrowsColor,
			};
		},
	},
	watch: {
		variantImage(newValue) {
			const index = this.images.findIndex((image) => image.url === newValue);

			if (index > -1) {
				this.currentIndex = index;
			}
		},
	},
	mounted() {
		this.enableCarouselMoveEvents(true);
	},

	beforeDestroy() {
		this.enableCarouselMoveEvents(false);
	},

	methods: {
		goToNextSlide() {
			this.currentIndex = this.currentIndex === this.images.length - 1 ? 0 : this.currentIndex + 1;
		},
		goToPreviousSlide() {
			this.currentIndex = this.currentIndex === 0 ? this.images.length - 1 : this.currentIndex - 1;
		},
		enableCarouselMoveEvents(isEnabled) {
			if (!this.isMoreThanOneImage) {
				return;
			}

			const { enableMoveEvents } = moveDirection({
				move: {
					drag: false,
					swipe: true,
				},
				onMoveLeft: () => this.goToNextSlide(),
				onMoveRight: () => this.goToPreviousSlide(),
			});

			enableMoveEvents(isEnabled, this.$refs.carouselRef);
		},
	},
});
</script>

<style lang="scss">
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.product-carousel {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: var(--image-max-width);
	overflow: hidden;

	&__image-wrapper {
		position: relative;
		display: flex;
		width: 100%;
		overflow: hidden;
		transition: height 0.2s ease-in;

		&--contain {
			height: var(--image-max-width);
			border: 1px solid $grey-400;

			@media screen and (max-width: 500px) {
				// to increase specificity
				&#{&} {
					height: 400px;
				}
			}
		}

		// hack to make image a responsive square according to it's width
		&::after {
			display: block;
			padding-bottom: 100%;
			content: "";
		}
	}

	&__image {
		width: 100%;
		object-fit: cover;
		height: 100%;
	}

	&__main-image {
		position: var(--image-position, relative);
		top: 0;
		left: 0;
		border-radius: var(--image-border-radius, 0);
		object-fit: var(--image-object-fit, cover);
		object-position: center;
	}

	&__image-content {
		z-index: 0;
		flex: 1 0 auto;
		width: 100%;
		overflow: hidden;
		font-size: 2rem;
		font-weight: bold;
	}

	&__arrow {
		position: absolute;
		z-index: 1;
		box-sizing: content-box;
		width: 75px;
		height: 100%;
		cursor: pointer;
		background-color: transparent;
		border: 0;
		outline: none;

		// to remove the blue box that appears on click in place of buttons
		-webkit-tap-highlight-color: transparent;

		&::after {
			position: relative;
			display: inline-block;
			width: 1em;
			height: 1em;
			margin-right: 0.5em;
			content: "";
			border-top: 0.2em solid var(--arrow-color, $dark);
			border-right: 0.2em solid var(--arrow-color, $dark);
			transform: rotate(225deg);
		}

		&--left {
			left: 0;
			padding-left: 20px;
			text-align: left;
		}

		&--right {
			right: 0;
			padding-right: 20px;
			text-align: right;

			&::after {
				transform: rotate(45deg);
			}
		}
	}

	&__image-list {
		display: flex;
		margin-top: 8px;
	}

	&__image-list-element {
		width: var(--image-max-width-small);
		height: var(--image-max-width-small);
		margin-right: 8px;
		cursor: pointer;
		opacity: 0.65;
		transition: opacity 0.3s ease-in-out;

		&--active {
			opacity: 1;
		}
	}

	&__dots-wrapper {
		position: absolute;
		bottom: 16px;
		display: none;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	&__dot-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		cursor: pointer;
		background: transparent;
		border: none;
	}

	&__dot {
		width: 10px;
		height: 10px;
		background-color: var(--arrow-color, $dark);
		border-radius: 100%;
		opacity: 0.5;
		transition: opacity 0.3s ease-in-out;

		&:not(:last-child) {
			margin-right: 6px;
		}

		&--active {
			opacity: 1;
		}
	}
}

@include site-engine-mobile {
	.product-carousel {
		max-width: none;

		&__image-wrapper {
			&--contain {
				height: 500px;
			}
		}

		&__image-list {
			display: none;
		}

		&__dots-wrapper {
			display: flex;
		}
	}
}
</style>
