<template>
	<div
		v-qa="`${data.type && data.type}:${id}`"
		class="block-grid-item use-m-margin"
		:style="computedStyles"
	>
		<Component
			:is="data.type"
			:id="id"
			:lcp="lcp"
			class="block-grid-item__component"
			:data="data"
			:mobile-block-padding="mobileBlockPadding"
			:element-width="elementWidth"
			:element-height="elementHeight"
		/>
	</div>
</template>

<script>
import { getGridItemSize } from '@zyro-inc/site-modules/utils/getGridItemSize';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import GridButtonProviderUser from '@/components/elements/GridButtonProviderUser.vue';
import GridEcommerceButtonProviderUser from '@/components/elements/GridEcommerceButtonProviderUser.vue';
import GridEmbedProviderUser from '@/components/elements/GridEmbedProviderUser.vue';
import GridFormProviderUser from '@/components/elements/GridFormProviderUser.vue';
import GridGalleryProviderUser from '@/components/elements/GridGalleryProviderUser.vue';
import GridImageProviderUser from '@/components/elements/GridImageProviderUser.vue';
import GridInstagramFeedProviderUser from '@/components/elements/GridInstagramFeedProviderUser.vue';
import GridMapProviderUser from '@/components/elements/GridMapProviderUser.vue';
import GridSocialIconsProviderUser from '@/components/elements/GridSocialIconsProviderUser.vue';
import GridStripeButtonProviderUser from '@/components/elements/GridStripeButtonProviderUser.vue';
import GridTextBoxProviderUser from '@/components/elements/GridTextBoxProviderUser.vue';
import GridVideoProviderUser from '@/components/elements/GridVideoProviderUser.vue';

import { defineComponent } from 'vue';

export default defineComponent({
	name: 'BlockGridItemUser',

	components: {
		GridButton: GridButtonProviderUser,
		GridStripeButton: GridStripeButtonProviderUser,
		GridEcommerceButton: GridEcommerceButtonProviderUser,
		GridForm: GridFormProviderUser,
		GridVideo: GridVideoProviderUser,
		GridTextBox: GridTextBoxProviderUser,
		GridMap: GridMapProviderUser,
		GridSocialIcons: GridSocialIconsProviderUser,
		GridGallery: GridGalleryProviderUser,
		GridImage: GridImageProviderUser,
		GridInstagramFeed: GridInstagramFeedProviderUser,
		GridEmbed: GridEmbedProviderUser,
	},

	props: {
		id: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		lcp: {
			type: Object,
			default: () => ({}),
		},
		mobileBlockPadding: {
			type: String,
			required: true,
		},
		blockData: {
			type: Object,
			required: true,
		},
	},

	computed: {
		settings() {
			return this.data.settings;
		},
		styles() {
			return this.settings.styles;
		},
		innerBackgroundValue({ data }) {
			const { innerBackground } = data;

			if (!innerBackground) {
				return {};
			}

			const currentBackground = innerBackground[innerBackground.current];

			if (innerBackground.current === 'image') {
				const imageBackground = {
					'--gridItemInnerBackground': `url(${currentBackground})`,
				};

				if ('overlay-opacity' in innerBackground) {
					imageBackground['--gridItemInnerBackgroundOverlayOpacity'] = innerBackground['overlay-opacity'];
				}

				return imageBackground;
			}

			return {
				'--gridItemInnerBackground': currentBackground,
			};
		},
		elementWidth() {
			return getGridItemSize(this.blockData, this.styles.position)?.width;
		},
		elementHeight() {
			return getGridItemSize(this.blockData, this.styles.position)?.height;
		},
		computedStyles() {
			return {
				...objectToCssVariables(this.styles),
				...this.innerBackgroundValue,
			};
		},
	},
});
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";
@import "@zyro-inc/site-modules/scss/components/BlockGridItem";

.block-grid-item {
	// Make only the actual component clickable
	// Prevents, for example, buttons that are over textboxes, from being non clickable
	pointer-events: none;

	&__component {
		pointer-events: all;
	}
}

@include site-engine-mobile {
	.block-grid-item {
		width: var(--m-width, 100%);

		&.use-m-margin {
			margin: var(--m-element-margin);

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}
</style>
