<template>
	<GridButton
		:tag-name="tagName"
		:href="href"
		:target="target"
		:rel="rel"
		:type="type"
		:button-type="buttonType"
		:content="content"
		:font-size-mobile="fontSizeMobile"
		:font-size-desktop="fontSizeDesktop"
		:font-family="fontFamily"
		:font-weight="fontWeight"
		:border-radius="borderRadius"
		:background-color="backgroundColor"
		:font-color="fontColor"
		:border-color="borderColor"
		:background-color-hover="backgroundColorHover"
		:font-color-hover="fontColorHover"
		:border-color-hover="borderColorHover"
	/>
</template>

<script>
import {
	defineComponent,
	computed,
} from 'vue';
import { useStore } from 'vuex';

import GridButton from '@zyro-inc/site-modules/components/elements/button/GridButton.vue';
import { useGridButton } from '@zyro-inc/site-modules/components/elements/button/useGridButton';

export default defineComponent({
	name: 'GridButtonProviderUser',

	components: {
		GridButton,
	},

	props: {
		data: {
			type: Object,
			required: true,
		},
	},

	setup(props, context) {
		const { state } = useStore();
		const {
			tagName,
			href,
			target,
			rel,
			type,
			buttonType,
			content,
			fontSizeMobile,
			fontSizeDesktop,
			fontFamily,
			fontWeight,
			borderRadius,
			backgroundColor,
			fontColor,
			borderColor,
			backgroundColorHover,
			fontColorHover,
			borderColorHover,
		} = useGridButton(props, context, {
			siteData: computed(() => state.website),
			locale: computed(() => state.currentLocale),
		});

		return {
			tagName,
			href,
			target,
			rel,
			type,
			buttonType,
			content,
			fontSizeMobile,
			fontSizeDesktop,
			fontFamily,
			fontWeight,
			borderRadius,
			backgroundColor,
			fontColor,
			borderColor,
			backgroundColorHover,
			fontColorHover,
			borderColorHover,
		};
	},
});
</script>
