import { computed } from 'vue';
import { useStore } from 'vuex';

export const useBlockEcommerceProduct = (props) => {
	const { getters } = useStore();
	const productId = computed(() => props.data.product.id);
	const blockStyle = computed(() => props.data.settings?.styles);
	const textColorVars = computed(() => props.data.textColorVars);
	const blockButtonText = computed(() => props.data.buttonText);
	const blockButtonStyle = computed(() => props.data.buttonStyle);
	const blockButtonType = computed(() => props.data.buttonType);
	const navigationArrowsColor = computed(() => props.data.navigationArrowsColor);
	const imageRatio = computed(() => props.data.imageRatio);
	const imageBorderRadius = computed(() => props.data.imageBorderRadius);
	const isQuantityPickerEnabled = computed(() => props.data.isQuantityPickerEnabled ?? true);
	const productPages = computed(() => getters.ecommerceProductPages);

	return {
		productId,
		blockStyle,
		blockButtonText,
		blockButtonStyle,
		blockButtonType,
		textColorVars,
		imageBorderRadius,
		navigationArrowsColor,
		imageRatio,
		isQuantityPickerEnabled,
		productPages,
	};
};
